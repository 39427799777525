import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { theme } from 'theme';
import { PageContextType } from 'types';

import Layout from 'components/templates/layout';

import Breadcrumb from '../components/atoms/breadcrumb';
import SimpleWrapper from '../components/molecules/simple-wrapper';
import ContactUsDirectlySection, {
  ContactUsDirectlySectionType,
} from '../components/organisms/contact-us-directly-section';
import HeadTitleDescription from '../components/organisms/head-title-description';
import { PostText } from '../components/organisms/post-renderer/post-components';
import { StyledWhistleblowersContentWrapper } from '../components/organisms/whistleblowers-content/whistleblowers-content.style';

type DataProps = {
  WhistleblowersPage: {
    whistleblowersData: {
      bannerwhistleblowers: {
        heading: string;
        text: string;
      };
      content: {
        contentsTheses: string;
        fieldGroupName: string;
      };
      contactRequests: ContactUsDirectlySectionType;
    };
  };
};

const WhistleblowersPage = ({
  pageContext,
  data: {
    WhistleblowersPage: {
      whistleblowersData: {
        bannerwhistleblowers: { text, heading },
        content: { contentsTheses },
        contactRequests,
      },
    },
  },
}: PageProps<DataProps, PageContextType>) => (
  <Layout {...{ pageContext }}>
    <HeadTitleDescription showDots titleTop={heading} subtitleTop={text}>
      <Breadcrumb {...{ pageContext }} />
    </HeadTitleDescription>
    <SimpleWrapper>
      <StyledWhistleblowersContentWrapper>
        <PostText
          fieldGroupName="Know_how_Knowhowpostdata_PostTemplate_Text"
          content={contentsTheses}
        />
      </StyledWhistleblowersContentWrapper>
    </SimpleWrapper>
    <ContactUsDirectlySection
      padding={`${theme.gap.xl} 0`}
      {...contactRequests}
      mobilePadding={`${theme.gap.mdl} ${theme.gap.sm}`}
    />
  </Layout>
);

export const query = graphql`
  query WhistleblowersPage($commonComponentsSlug: String!) {
    WhistleblowersPage: wpPage(slug: { eq: "report-an-irregularity" }) {
      whistleblowersData {
        bannerwhistleblowers {
          heading
          text
        }
        content {
          contentsTheses
          fieldGroupName
        }
        contactRequests {
          heading
          contactCards {
            title
            place
            data
            contactList {
              dataContact
              buttonLink
              linkButton {
                label
                link {
                  ariaLabel
                  internal: wpInternal {
                    ... on WpPage {
                      id
                      uri
                    }
                  }
                  external {
                    target
                    url
                  }
                }
                icon {
                  name
                }
              }
            }
          }
        }
      }
    }
    commonComponents: wpPage(slug: { eq: $commonComponentsSlug }) {
      commonComponentsData {
        moreInformationSection {
          heading
          cardList {
            text
            link {
              ariaLabel
              internal: wpInternal {
                ... on WpPage {
                  id
                  uri
                }
              }
              external {
                target
                url
              }
            }
            icon {
              name
            }
          }
        }
      }
    }
  }
`;
export default WhistleblowersPage;
